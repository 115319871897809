import {clickEventService} from 'core-tracking-objects';

class ClickEventObjectDrawer {
	/**
	* registers to ClickEventService
	* @constructor singleton
	*/
	constructor() {
		if (ClickEventObjectDrawer._instance) {
			return ClickEventObjectDrawer._instance;
		}

		clickEventService.clickSelectors.push('.nm-drawer-button');

		clickEventService.register(ClickEventObjectDrawer.getTrackingData);
		ClickEventObjectDrawer._instance = this;
	}

	/**
	* getTrackingData - collects the tracking data
	* @param {Object} eventData_ - event data
	* @returns {Promise} promise - returns promise for linkObject
	*/
	static async getTrackingData(eventData_) {
		const target = eventData_.currentTarget;

		if (!target || !target.classList.contains('nm-drawer-button')) {
			return {};
		}

		return {
			attributes: {
				label: ClickEventObjectDrawer._getButtonLabel(target)
			},
			eventInfo: {
				eventAction: 'content',
				eventName: ClickEventObjectDrawer._checkOpenCloseStatus(target)
			}
		};
	}

	/**
	 * @param {Element} target_ - click dom Element
	 * @returns {string} depending on if drawer is opend or closed
	 */
	static _checkOpenCloseStatus(target_) {
		let returnString = '';
		let parentDrawer = target_.closest('.nm-module-drawer');

		if (parentDrawer !== null) {
			if (parentDrawer.classList.contains('nm-open')) {
				returnString = 'drawer open';
			}
			else {
				returnString = 'drawer close';
			}
		}
		return returnString;
	}

	/**
	 * @param {Element} target_ - click dom Element
	 * @returns {string} Text of Button label
	 */
	static _getButtonLabel(target_){
		let returnLabel = '';
		let parentContainer = target_.closest('.nm-drawer-header');
		if (parentContainer) {
			let labelElement = parentContainer.querySelector('.nm-drawer-text-wrapper .nm-drawer-text');
			if (labelElement) {
				returnLabel = labelElement.innerText;
			}
		}
		return returnLabel;
	}
}

/**
 * Singleton instance
 * @type {ClickEventObjectDrawer}
 * @public
 * @static
 */
const clickEventObjectDrawer = new ClickEventObjectDrawer();
export {clickEventObjectDrawer, ClickEventObjectDrawer};
